import { LinkPaginated } from "./PaginatedInterface";

export enum UserRole {
    Admin = 1,
    Analyst = 3,
    Customer = 5,
    Commerce = 4,
    Basic = 10,
    LeaderCollection = 14,
    AuxCollection = 15,
    AuxCustomerService = 16,
    AuxAccounting = 17,
}


export interface UserPaginatedInterface {
  ok: boolean;
  message: string;
  code: number;
  status: string;
  data: UserDataPaginated;
}

export interface UserDataPaginated {
  current_page: number;
  data: UserInterface[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: LinkPaginated[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null;
  to: number;
  total: number;
}

export interface UserResponseInterface {
  ok: boolean;
  message: string;
  code: number;
  status: string;
  data: UserInterface;
}

export interface UserInterface {
  data: UserInterface[];
  id: number;
  name: string;
  email: string;
  state: string;
  role_id: number;
  role: RoleInterface;
}

export interface RoleResponseInterface {
  ok: boolean;
  message: string;
  code: number;
  status: string;
  data: RoleInterface[];
}

export interface RoleInterface {
  id: number;
  created?: null;
  modified?: string;
  name: string;
}
