// Angular import
import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
// Interfaces
import { UserRole } from '../interfaces/user.interface';
import { Menu } from '../interfaces/menu.inteface';
// Services
import { AuthService } from '../../auth/login/services/auth.service';


@Injectable({ providedIn: 'root' })
export class NavService {
      // Oservables
      private unsubscriber = new Subject<void>();
      public screenWidth = new BehaviorSubject<number>(window.innerWidth);
      public items = new BehaviorSubject<Menu[]>([]);
      // Properties
      public collapseSidebar = window.innerWidth < 991;
      public fullScreen = false;
      private role: UserRole | null = null;
      public search: boolean = false;


      constructor(private router: Router, private authService: AuthService) {
            this.init();
      }

      /**
       * Inicializa el servicio
       */
      private init(): void {
            this.role = this.authService.getUser()?.role_id || null;
            this.setMenuForRole(this.role);

            this.subscribeToResizeEvents();
      }

      /**
       * Se suscribe a los eventos de redimensionamiento de la ventana
       */
      private subscribeToResizeEvents(): void {
            fromEvent(window, 'resize')
                  .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
                  .subscribe((event: Event) => {
                        const target = event.target as Window;
                        this.setScreenWidth(target.innerWidth);
                        this.collapseSidebar = target.innerWidth < 991;
                  });
      }

      /**
       *  Establece el ancho de la pantalla
       * @param width < number >
       */
      private setScreenWidth(width: number): void {
            this.screenWidth.next(width);
      }

      /**
       *  Establece el menú en función del rol
       * @param role < UserRole | null >
       */
      private setMenuForRole(role: UserRole | null): void {
            const menu = role ? this.menus[role] : [];
            this.items.next(menu || []);
      }

      /**
       *  Actualiza el menú en función del rol
       * @param role < UserRole >
       */
      public updateMenu(role: UserRole): void {
            this.setMenuForRole(role);
      }

      // Menu Admin
      private readonly MENUITEMSADMIN: Menu[] = [
            { title: 'Inicio', icon: 'fa-solid fa-house fa-sm', type: 'link', active: true, path: '/panel/inicio' },
            { title: 'Clientes', icon: 'fa-solid fa-users fa-sm', type: 'link', active: false, path: '/clientes/listado' },
            { title: 'Créditos', icon: 'fa-regular fa-cart-shopping ', type: 'link', active: false, path: '/creditos/listado' },
            { title: 'Códigos OTP', icon: 'fa-solid fa-code-compare ', type: 'link', active: false, path: '/codigos-otp/listado/1' },
            { title: 'Gestión tasa mora', icon: 'fa-solid fa-users ', type: 'link', active: false, path: '/gestion-tasa-mora/listado', },
            {
                  title: 'Servicio al cliente', icon: 'fa-solid fa-bell-concierge ', type: 'sub', active: false,
                  children: [
                        // { title: 'Cartera clientes', path: '/cobranza/arbol-gestion', type: 'link',},
                        { title: 'Gestión diaria', path: '/servicio-cliente/gestion-diaria', type: 'link', },
                        { title: 'Parametros', path: '/servicio-cliente/parametros', type: 'link', },
                        { title: 'Chat', path: '/servicio-cliente/whatsapp', type: 'link', },
                        { title: 'Liwa Sms', path: '/servicio-cliente/liwa', type: 'link', },
                  ],
            },
            // { title: 'Lineas de crédito', icon: 'fa-solid fa-chart-bar', type: 'link', active: false, path: '',},
            { title: 'Pagos clientes', icon: 'fa-solid fa-money-bill-1-wave', type: 'link', active: false, path: '/pagos/clientes-listado', },
            { title: 'Pagos proveedores', icon: 'fa-solid fa-money-bill-1-wave', type: 'link', active: false, path: '/pagos/proveedores-listado',},
            { title: 'Parametros', icon: 'fa-solid fa-square-plus', type: 'link', active: false, path: '/parametros', },
            { title: 'Proveedores', icon: 'fa-solid fa-users', type: 'link', active: false, path: '/proveedores/listado', },
            {
                  title: 'Solicitudes', icon: 'fa-solid fa-code-pull-request', type: 'sub', active: false,
                  children: [
                        { title: 'Por Lista', path: '/solicitudes/listado', type: 'link', },
                        { title: 'Por Tarjeta', path: '/solicitudes/tarjeta', type: 'link', },
                        // { title: 'Historial', path: '', type: 'link',}
                  ]
            },
            {
                  title: 'Siigo', icon: 'fa-solid fa-code-pull-request', type: 'sub', active: false,
                  children: [
                        { title: 'Clientes Siigo', path: '/siigo/clientes', type: 'link', },
                        { title: 'Pagos Siigo', path: '/siigo/pagos', type: 'link', },
                  ]
            },
            { title: 'Sucursales', icon: 'fa-solid fa-store', type: 'link', active: false, path: '/sucursales/listado', },
            { title: 'Usuarios', icon: 'fa-solid fa-users', type: 'link', active: false, path: '/usuarios/listado', },
            {
                  title: 'Auditorias', icon: 'fa-solid fa-code-pull-request', type: 'sub', active: false,
                  children: [
                        { title: 'Créditos', path: '/auditorias/creditos', type: 'link', }
                  ]
            }
      ];

      // Menu Analyst
      MENUITEMSANALYST: Menu[] = [
            { title: 'Inicio', icon: 'home', type: 'link', active: true, path: '/panel/inicio', },
            {
                  title: 'Solicitudes de cupo', icon: 'page', type: 'sub', active: false,
                  children: [{ title: 'Por Lista', path: '/solicitudes-credito/listado', type: 'link', },
                  { title: 'Por Tarjeta', path: '/solicitudes-credito/tarjeta', type: 'link', },],
            },
      ];
      //  Menu Customer
      MENUITEMSCUSTOMER: Menu[] = [
            { title: "Inicio", icon: "fa-solid fa-house fa-sm", type: "link", active: true, path: "/clientes/inicio", },
            { title: "Solicitudes", icon: "fa-solid fa-code-pull-request fa-sm", type: "link", active: false, path: "/clientes/solicitudes-credito", },
            { title: "Mis compras", icon: "fa-regular fa-cart-shopping fa-sm", type: "link", active: false, path: "/clientes/compras", },
            { title: "Mis pagos", icon: "fa-solid fa-money-bill-1-wave fa-sm", type: "link", active: false, path: "/clientes/pagos", },
            { title: "Mis compras sin entregar", icon: "fa-regular fa-cart-shopping fa-sm", type: "link", active: false, path: "/clientes/compras-sin-entregar", },];

      // Menu Commerce
      MENUITEMSCOMMERCE: Menu[] = [
            { title: 'Inicio', icon: 'fa-solid fa-house', type: 'link', active: true, path: '/webhook/inicio', },
            { title: 'Clientes', icon: 'fa-solid fa-users', type: 'link', active: false, path: '/clientes/listado', },
            { title: 'Créditos', icon: 'fa-regular fa-cart-shopping', type: 'link', active: false, path: '/creditos/listado', },
            {
                  title: 'Solicitudes', icon: 'fa-solid fa-code-pull-request', type: 'sub', active: false,
                  children: [
                        { title: 'Por Lista', path: '/solicitudes/listado', type: 'link', },
                        { title: 'Por Tarjeta', path: '/solicitudes/tarjeta', type: 'link', },
                        { title: 'Historial', path: '', type: 'link', }
                  ],
            },
            { title: 'Sucursales', icon: 'fa-solid fa-store', type: 'link', active: false, path: '/sucursales/listado', },
            { title: 'Weebhook', icon: 'fa-solid fa-plug', type: 'link', active: false, path: '/webhook/subscriptions', },
      ];

      // Menu Basic
      MENUITEMSBASIC: Menu[] = [
            { title: 'Clientes', icon: 'users', type: 'link', active: false, path: '/clientes/listado', },
            { title: 'Créditos', icon: 'cart', type: 'link', active: false, path: '/creditos/listado', },
      ];

      // Menu LeaderCollection
      MENUITEMSLEADERCOLLECTION: Menu[] = [
            { title: 'Inicio', icon: 'home', type: 'link', active: true, path: '/panel/inicio', },
            { title: 'Créditos', icon: 'cart', type: 'link', active: false, path: '/creditos/listado', },
            {
                  title: 'Servicio al cliente', icon: 'other', type: 'sub', active: false,
                  children: [{ title: 'Cartera clientes', path: '/cobranza/cartera-clientes', type: 'link', },
                  { title: 'Gestión diaria', path: '/cobranza/gestion-diaria', type: 'link', },
                  { title: 'Parametros', path: '/cobranza/parametros', type: 'link', },
                  { title: 'Chat', path: '/servicio-cliente/whatsapp', type: 'link', },],
            }
      ];

      // Menu AuxCollection
      MENUITEMSAUXCOLLECTION: Menu[] = [
            { title: 'Inicio', icon: 'home', type: 'link', active: true, path: '/panel/inicio', },
            { title: 'Créditos', icon: 'cart', type: 'link', active: false, path: '/creditos/listado' },
            {
                  title: 'Servicio al cliente', icon: 'other', type: 'sub', active: false,
                  children: [{ title: 'Cartera clientes', path: '/cobranza/cartera-clientes', type: 'link', },
                  { title: 'Chat', path: '/servicio-cliente/whatsapp', type: 'link', },],
            }
      ];

      // Menu AuxAccounting
      MENUITEMSAUXACCOUNTING: Menu[] = [
            { title: 'Inicio', icon: 'home', type: 'link', active: true, path: '/panel/inicio', },
            { title: 'Clientes ', icon: 'users ', type: 'link ', active: false, path: '/clientes/listado', },
            { title: 'Créditos', icon: 'cart', type: 'link', active: false, path: '/creditos/listado', },
            { title: 'Pagos clientes', icon: 'money', type: 'link', active: false, path: '/pagos-clientes/listado', },
            {
                  title: 'Siigo', icon: 'page', type: 'sub', active: false,
                  children: [{ title: 'Clientes Siigo', path: '/siigo/clientes/listado/1', type: 'link', },],
            }
      ];

      // Menu AuxCustomerService
      MENUITEMSAUXCUSTOMERSERVICE: Menu[] = [
            { title: 'Inicio', icon: 'home', type: 'link', active: true, path: '/panel/inicio', },
            { title: 'Créditos', icon: 'cart', type: 'link', active: false, path: '/creditos/listado', },
            {
                  title: 'Servicio al cliente', icon: 'other', type: 'sub', active: false,
                  children: [
                        {
                              title: 'Cartera clientes',
                              path: '/cobranza/cartera-clientes',
                              type: 'link',
                        },
                        {
                              title: 'Gestión diaria',
                              path: '/cobranza/gestion-diaria',
                              type: 'link',
                        },
                        {
                              title: 'Chat',
                              path: '/servicio-cliente/whatsapp',
                              type: 'link',
                        },
                  ],
            },
      ];


      private menus: { [key in UserRole]?: Menu[] } = {
            [UserRole.Admin]: this.MENUITEMSADMIN,
            [UserRole.Analyst]: this.MENUITEMSANALYST,
            [UserRole.Customer]: this.MENUITEMSCUSTOMER,
            [UserRole.Commerce]: this.MENUITEMSCOMMERCE,
            [UserRole.Basic]: this.MENUITEMSBASIC,
            [UserRole.LeaderCollection]: this.MENUITEMSLEADERCOLLECTION,
            [UserRole.AuxCollection]: this.MENUITEMSAUXCOLLECTION,
            [UserRole.AuxCustomerService]: this.MENUITEMSAUXCUSTOMERSERVICE,
            [UserRole.AuxAccounting]: this.MENUITEMSAUXACCOUNTING,
      };


}
